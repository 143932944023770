<template>
  <section style="height: 100%">

    <map-nav @update-map="updateMap"></map-nav>

    <div class="map-container">

      <div class="menu-section" :class="showList ? '' : 'hide'">
        <div class="toggle-btn" @click="showList = !showList">
          <i :class="showList ? 'el-icon-caret-left' : 'el-icon-caret-right'"></i>
        </div>
        <div class="title">仓库列表</div>
          
        <div v-if="!!cardData.length" style="height: 100%;">
          <el-scrollbar
            style="height: calc(100% - 90px)"
            wrap-style="overflow-x:hidden;"
          >
            <div class="warehouse-list">
              <Card-s
                v-for="(it, ind) in cardData"
                :key="ind"
                :info="it"
                :zoom="1"
                tipTxt="推荐"
              ></Card-s>
            </div>
          </el-scrollbar>

          <div class="pagination-section">
            <el-pagination background layout="prev, pager, next" @current-change="changePage" :page-size="parameter.pageSize" :total="parameter.total"></el-pagination>
          </div>
        </div>

        <el-empty description="未找到相关仓库数据，请尝试更换关键字搜索" v-else></el-empty>

      </div>


      <MapView :overlayData="mapList"></MapView>

      <!-- <baidu-map
        :center="center"
        :min-zoom="6"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        style="width: 100%; height: 100%"
        @ready="handler"
        @click="getClickInfo"
        @zoomend="syncCenterAndZoom"
      >
      </baidu-map> -->
      <!-- <div style="width: 100%; height: 100%" class="container" id="container"></div> -->
    </div>
  </section>
</template>
<script>

import MapView from "./components/MapView"

import mapApi from '@/api/map';
import house from "@/api/housedetail.js";

export default {
  components: {
    MapView
  },
  data() {
    return {
      // 地址信息
      address: null,
      center: { lng: 106.404, lat: 33.915 },
      //地图展示级别
      zoom: 6,
      showList: true,
      cardData: [],
      BMap: null,
      isInit: true,

      mapList: [],
      
      parameter: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        warehouseName: this.$route.query.name,
        province: this.$route.query.provinceId,
        city: this.$route.query.cityId,
        county: this.$route.query.countyId,
        categoryIds: []
      }

      
    };
  },
  methods: {
    changePage(pageNum) {
      this.parameter.pageNum = pageNum;
      this.initAsiderData();
    },
    handler({ BMap, map }) {
      this.BMap = map;
    },
    // 根据缩放层级添加标记点
    updateMap(params) {
      this.parameter = params
      this.initAsiderData();
      this.initMapData();
      // 接口请求数据后  isInit = false初始化
      // this.isInit = false;
      // var point = new BMap.Point(106.404, 33.915);
      // this.zoom = 6;
      // this.BMap.centerAndZoom(point, this.zoom);
      // this.markersArr = data;
      // this.addMark();
    },
    addMark() {
      this.BMap.clearOverlays();
      // console.log("this.zoom", this.zoom);
      // 省
      if (this.zoom <= 7) {
        this.addPList();

        return;
      }
      // 市
      if (this.zoom <= 8) {
        this.addCityList();

        return;
      }
      // 区 区点位需要点击弹出层
      this.addAreaList();
    },
    setZoom(pt) {
      this.zoom = this.zoom + 2;
      this.center = pt;
      this.addMark();
    },
    addPList() {
      let pList = this.markersArr.filter((el) => el.area.level === 1);
      pList.forEach((item) => {
        let pt = new window.BMap.Point(item.area.lng, item.area.lat);
        let label = new BMap.Label(`${item.area.name} ${item.count}`, {
          position: pt,
        });
        let that = this;
        label.addEventListener("click", function () {
          that.setZoom(pt);
        });
        let styleData = {
          color: "#fff",
          fontSize: "16px",
          backgroundColor: "rgba(0, 176, 121, 1)",
          border: "0",
          borderRadius: "50%",
          width: "64px",
          height: "64px",
          lineHeight: "64px",
          textAlign: "center",
        };
        label.setStyle(styleData);
        this.BMap.addOverlay(label);
      });
    },
    addCityList() {
      // 二级点位
      let pList = this.markersArr.filter((el) => el.area.level === 2);
      pList.forEach((item) => {
        let pt = new window.BMap.Point(item.area.lng, item.area.lat);
        let label = new BMap.Label(`${item.area.name} ${item.count}`, {
          position: pt,
        });
        let that = this;
        label.addEventListener("click", function () {
          that.setZoom(pt);
        });
        // 默认样式
        let styleData = {
          color: "#fff",
          fontSize: "16px",
          backgroundColor: "rgba(0, 176, 121, 1)",
          border: "0",
          borderRadius: "50%",
          width: "64px",
          height: "64px",
          lineHeight: "64px",
          textAlign: "center",
        };
        label.setStyle(styleData);
        this.BMap.addOverlay(label);
      });
    },
    addAreaList() {
      // 查询所有三级点位
      let pList = this.markersArr.filter((el) => el.area.level === 3);
      pList.forEach((item) => {
        let list = item.warehouseInfoBasicList || [];
        //  遍历三级点位的数据集
        list.forEach((el) => {
          let pt = new BMap.Point(el.longitude, el.latitude);
          let label = new BMap.Marker(pt);
          label.__data = el;
          // 创建信息窗口对象
          var opts = {
            position: new BMap.Point(el.longitude, el.latitude),
            offset: new BMap.Size(0, 100),
          };
          var infoWindow = new BMap.InfoWindow(el.warehouseName, {
            width: 150,
            height: 50,
          });
          let that = this;
          label.addEventListener("click", () => {
            that.BMap.openInfoWindow(infoWindow, opts.position); //开启信息窗口
          });

          this.BMap.addOverlay(label);
        });
      });
    },
    getClickInfo(e) {
      // 创建地理编码实例
      const myGeo = new BMap.Geocoder();
      // 根据坐标逆解析地址
      myGeo.getLocation(new BMap.Point(e.point.lng, e.point.lat), (result) => {
        // console.log(result, "result-->>>>");
        if (result) {
          this.address = result.address;
        }
      });
    },
    syncCenterAndZoom(e) {
      // const { lng, lat } = e.target.getCenter();
      this.zoom = e.target.getZoom();
      if (!this.isInit) {
        this.addMark();
      }
    },

    initMapData() {
      const { province, city, county, categoryIds, warehouseName } = this.parameter;
      
      mapApi.queryWarehouselist({
        province,
        city,
        county,
        categoryIds: categoryIds[0] || '',
        warehouseName
      }).then(({ data: res }) => {
        const data = res || [];
        this.mapList = data.map(item => ({...item, active: false}));
      })
    },

    // 初始化侧边数据
    initAsiderData() {
      house.warehousePageInfo(this.parameter)
      .then((res) => {
        this.cardData = res.rows;
        this.parameter.total = res.total;
      });
    }
  },
  mounted() {
    this.initAsiderData();
    this.initMapData();
  },
};
</script>
<style lang="less" scoped>
  .map-container {
    position: relative;
    height: calc(100vh - 67px);

    .menu-section {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 100;
      width: 600px;
      height: 100%;
      padding: 0 20px 20px 20px;
      box-sizing: border-box;
      transition: all .3s;
      background: #fff;

      .title {
        height: 60px;
        line-height: 60px;
        background: #fff;
        font-weight: bold;
      }

      .warehouse-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .card {
          margin-bottom: 20px;
        }
      }

      .pagination-section {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 8px;
        border-top: 1px solid #eee;
      }

      .toggle-btn {
        position: absolute;
        top: calc(50% - 65px);
        right: -40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 65px;
        background: #6a6a6aa0;
        background-position: center;
      }
    }

    .hide {
      left: -600px;
    }
  }
</style>
<style lang="less">

  .map-container .el-icon-arrow-right, .map-container .el-icon-arrow-left{
    color: #ccc;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #00b079;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #00b079;
  }

</style>