<template>
  <BaiduMap
    class="bm-priver-view"
    ak="gnkvfgm7dTMZz34MaslbOYGcRw8Srxuq"
    ref="bmMap"
    :center="center"
    :scroll-wheel-zoom="true"
    :mapClick="false"
    :zoom="zoom"
    @ready="readyMap"
    @zoomend="mapZoomEnd"
  >

    <BmMarker
      v-for="item in markerList"
      :key="item.warehouseId"
      :dragging="false"
      :position="{ lng: item.longitude, lat: item.latitude }"
      @click="infoWindowOpen(item)"
    >
      <bm-info-window
        :show="item.showFlag"
        @close="infoWindowClose(item)"
      >
        <div class="info-dialog"  @click="jumpDetails(item)">
          <div class="info-pic"
            :style="'background-image:url('+item.thumbnail+');'">
          </div>
          <ul class="info-msg">
            <li :title="item.warehouseName">
              <span>{{item.warehouseName}}</span>
            </li>
            <li class="tags">
              <span v-for="val in item.tags" :key="val.tagId">{{val.itemName}}</span>
            </li>
            <li class="btns">
              <Button size="small">详情</Button>
            </li>
          </ul>
        </div>
      </bm-info-window>
    </BmMarker>

    <CustomOverlay
      v-for="(item, index) in overlayList"
      :key="index"
      :position="{ lng: item.lng, lat: item.lat }"
      :addressName="item.name"
      :total="item.total"
      @click="handleOverlay"
    >
    </CustomOverlay>
  </BaiduMap>
</template>

<script>
import { BaiduMap, BmMarker, BmInfoWindow, BmView } from "vue-baidu-map";
import CustomOverlay from "./CustomOverlay";

import { getFlatData } from '@/utils/flat';
import house from "@/api/housedetail"

export default {
  components: {
    BaiduMap,
    BmMarker,
    BmInfoWindow,
    CustomOverlay,
    BmView,
  },
  props: {
    overlayData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      center: "中国",
      active: false,
      zoom: 6,
      level: 0,
      /* 信息窗体 */
      windowInfo: {showFlag: false},
      /* 聚合 point */
      overlayList: [],
      /* 散点 point */
      markerList: [],

      // ----------------------------------------------------
      markerInfo: { showFlag: false },

      test: [],
      BMap: null,
      map: null,
    };
  },
  watch: {
    overlayData(val) {
      this.zoom = 6;
      this.overlayList = val;
    }
  },
  methods: {
    readyMap({ BMap, map }) {
      this.BMap = BMap;
      this.map = map;
    },
    
    mapZoomEnd({ target }) {
      this.zoom = target.getZoom();
      this.level = this.getZoomLevel(this.zoom);
      this.formatData();
    },

    handleOverlay({ position }) {
      const zoom = Number(this.zoom);

      if ( zoom < 8 )                  this.zoom = 8;
      if ( zoom >= 8 && zoom <= 10 )   this.zoom = 10; 
      if ( zoom >= 10 )                this.zoom = 12;

      this.center = position
    },

    jumpDetails({ warehouseId:id }) {
      const { href } = this.$router.resolve({
        path: "/detail",
        query: { id }
      });
      window.open(href, '_blank');
    },

    changeNative(val) {
      this.$set(val, 'active', !val.active);
    },

    infoWindowClose(item) {
      item.showFlag = false;
    },

    infoWindowOpen(item) {

      // if( item.warehouseName ) {
      //   return this.$set(item, 'showFlag', true);
      // }

      house.warehouseDetailById(item.warehouseId).then(({ data }) => {
        Object.assign(item, data)
        this.$set(item, 'showFlag', true);
      })

    },

    resizeMapPoint() {
      this.markerList = [];
      this.overlayList = [];
    },

    getZoomLevel(zoom) {
      let level = 0;
      if (zoom >= 8  && zoom <  10) level = 1
      if (zoom >= 10 && zoom <= 11) level = 2;
      if (zoom >= 12) level = 3;
      return level
    },
    
    formatData() {
      this.markerList = [];
      this.overlayList = [];

      if ( this.zoom <= 11 ) {
        this.overlayList = getFlatData(this.overlayData, 0, this.level);
      } else {
        this.markerList = getFlatData(this.overlayData, 0, this.level);
      }
    },
    
  }
};
</script>

<style lang="less" scoped>

@themeColor: #00b079;

.bm-priver-view {
  width: 100%;
  height: 100%;

  .info-dialog {
    width: 420px;
    padding-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;

    .info-pic {
      width: 100px;
      height: 70px;
      background-size: 100% 100%;
    }

    .info-msg {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      flex: 1;
      height: 70px;
      padding-left: 20px;

      li span {
        // .word_collapse(2);
      }

      li:nth-child(2) {
        font-size: 14px;
        color: #999;
        display: flex; 
        align-items: center; 
        justify-content: flex-start;
        span {
          font-size: 12px;
          padding: 4px;
          border: 1px solid #eee;
          margin-right: 5px;
        }
      }
      .btns {
        justify-content: flex-end;
        display: none;
        width: 100%;
      }
      li:nth-child(3) span {
        color: blue;
        text-align: right;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}

.marker-overlay-container {
  color: red;
  font-size: 30px;

  .adress-icon:hover {
    color: @themeColor;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .bm-priver-view {
    .info-dialog {
      width: 300px;
      padding-top: 20px;
      .info-msg {
        li:nth-child(2) {
          display: none;
        }
        .btns {
          display: flex;
        }
      }
    }
  }
}
</style>

<style lang="less">
.bm-priver-view {
  .BMap_pop {
    div {
      border: 0 !important;
    }
    div:nth-child(8) {
      display: none;
    }
  }
  .anchorBL {
    display: none;
  }
}
</style>