export const flatItem = (item, currentDeep, depth, context = [], ) => {
  item.deep = currentDeep || 0;
  item.context = context;
  if (item.children && currentDeep !== depth) {
    return item.children
      .map((child, index) =>
        flatItem(child, currentDeep + 1, depth, [...context, index])
      )
      .flat();
  } else {
    return [item];
  }
}

export const getFlatData = (sourceData, currentDeep, depth) => {
  return sourceData.map(item => flatItem(item, currentDeep , depth)).flat();
} 