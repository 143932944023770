<template>
  <bm-overlay
    ref="customOverlay"
    pane="labelPane"
    class="custom-overlay"
    @draw="draw"
  >
    <div class="ring-overlay-container" @click="handleClick">
      <p>{{ addressName }}</p>
      <p>{{ total }}</p>
    </div>
  </bm-overlay>
</template>

<script>
import { BmOverlay } from "vue-baidu-map";

export default {
  components: {
    BmOverlay,
  },
  props: {
    addressName: {
      type: String,
      default: ''
    },
    total: {
      type: Number
    },
    position: {
      type: Object,
      required: true,
    },
  },
  watch: {
    position: {
      handler() {
        this.$refs.customOverlay.reload();
      },
      deep: true,
    },
  },
  data() {
    return {
      active: false
    }
  },
  methods: {
    handleClick() {
      this.$emit("click", {position: this.position});
    },
    draw({ el, BMap, map }) {
      const { lng, lat } = this.position;
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
      el.style.left = pixel.x - 60 + "px";
      el.style.top = pixel.y - 20 + "px";
    },
  },
};
</script>

<style lang="less" scoped>
.custom-overlay {
  position: absolute;
  width: 80px;
  height: 80px;

  .ring-overlay-container {
    border-radius: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 176, 121, 0.7);
    color: #fff;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    transition: all 0.3s;

    p {
      color: #fff;
    }

    p:nth-child(1) {
      padding-bottom: 10px;
    }
    
    &:hover {
      background: #00b079;
    }

  }

  

}
</style>