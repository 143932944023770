import request from '@/utils/request';

const searchApi = {

  queryWarehouselist: params => request.get('/system/front/warehouseList', { params }),

  queryRecommendInfo: params => request.get('/system/front/homeRecommendlist', { params }),


}

export default searchApi;